<template>
  <Container>
    <div class="page">
      <h1>유라 수입서류조회</h1>
      <FilterForm :value="filter" @update="updateFilter"/>
      <ImportDocument :item="item" @resync="resync(item.invoice_no)" v-for="item in data" :key="item.invoice_no" />
    </div>
  </Container>
</template>

<script>
import { sleep, api, apiUrl } from '@/utils'
import Frame from '@/views/Frame'

import FileButton from './FileButton'
import FilterForm from './FilterForm'
import ImportDocument from './ImportDocument'

// invoice_no
// creared_at
// seller
// shipping_date
// all_file
// inv_file
// inv_d_file
// pack_file
// processing_price_file
// bl_file
// certificate_file
// insurance_file
// bom1_file
// bom2_file
// bom_sap_file

export default {
  name: 'YuraImportDocument',
  components: {
    Container: Frame,
    FilterForm,
    ImportDocument,
  },
  created () {
    this.load()
  },
  computed: {
    filter () {
      return this.$route.query
    }
  },
  watch: {
    '$route.query': 'load'
  },
  methods: {
    updateFilter (filter) {
      this.$router.push({ query: filter })
    },
    async load () {
      const response = await api('GET', '/yura/import/document/list', this.$route.query)
      this.data = response.data
    },
    async resync (invoiceNo) {
      const response = await api('POST', '/yura/import/document/resync', {
        invoice_no: invoiceNo
      })
      alert(`새로고침 요청 완료\nInvoice NO: ${invoiceNo}`)
      this.load()
    },
  },
  data () {
    return {
      data: []
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  padding: 0 40px;
}
</style>
