<template>
  <div class="import-document">
    <div class="row">
      <div class="invoice-no">{{ item.invoice_no }}</div>
      <div class="sillar">{{ item.seller }}</div>
    </div>
    <div class="info">
      <div class="shipping-date">선적일: {{ item.shipping_date }}</div>
    </div>
    <div class="documents">
      <span v-if="lastResyncRequest && !lastResyncRequest.resynced_at">새로고침 대기중({{lastResyncRequest.requested_at}})</span>
      <span v-if="lastResyncRequest && lastResyncRequest.resynced_at">새로고침 완료({{lastResyncRequest.resynced_at}})</span>
      <button @click="$emit('resync')">새로고침</button>
    </div>
    <div class="documents">
      <FileButton :document="item" filetype="all">ALL</FileButton>
      <FileButton :document="item" filetype="inv">INV</FileButton>
      <FileButton :document="item" filetype="inv_d">INV-D</FileButton>
      <FileButton :document="item" filetype="pack">PACK</FileButton>
      <FileButton :document="item" filetype="processing_price">임가공<br/>단가표</FileButton>
      <FileButton :document="item" filetype="bl">B/L</FileButton>
      <FileButton :document="item" filetype="certificate">면장</FileButton>
      <FileButton :document="item" filetype="insurance">보험</FileButton>
      <FileButton :document="item" filetype="bom1">BOM1</FileButton>
      <FileButton :document="item" filetype="bom2">BOM2</FileButton>
      <FileButton :document="item" filetype="bom_sap">BOM<br/>SAP</FileButton>
    </div>
    <div class="documents">
      <FileButton :document="item" filetype="import_declaration_1">신고서<br/>변환</FileButton>
    </div>
  </div>
</template>

<script>
import { sleep, api, apiUrl } from '@/utils'

import FileButton from './FileButton'

// invoice_no
// creared_at
// seller
// shipping_date
// all_file
// inv_file
// inv_d_file
// pack_file
// processing_price_file
// bl_file
// certificate_file
// insurance_file
// bom1_file
// bom2_file
// bom_sap_file
const EMPTY_UUID = '00000000-0000-0000-0000-000000000000'

export default {
  name: 'HomeView',
  components: {
    FileButton,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    lastResyncRequest () {
      let resyncRequest
      for (const request of this.item.resync_requests) {
        if (!resyncRequest || request.requested_at > resyncRequest.requested_at) {
          resyncRequest = request
        }
      }
      return resyncRequest
    },
  },
}
</script>

<style scoped lang="scss">
.import-document {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  & > .row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 10px;
    & > .invoice-no {
      font-size: 26px;
      font-weight: bold;
      margin-right: 10px;
    }
    & > .sillar {
      font-size: 14px;
      color: #666;
    }
  }
  & > .info {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 10px;
    & > .shipping-date {
      font-size: 16px;
    }
  }
  & > .documents {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
}
</style>

